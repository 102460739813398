.masthead {
    background-image: url(../../../public/img/img3.jpg);
    background-size: cover;
    position: relative;
}

#container-showcase {
    padding-bottom: 25px;
}

.masthead h1 {
    color: #fff;
}

.masthead .color-h1-blue {
    color: #437DBF;
    margin-top: -0.3em;
}

#layout-info {
    text-align: left;
}

@media (min-width: 320px) {
    #container-showcase {
        padding-top: 25vh;
        margin-left: 10px;
    }
    
    .masthead h1 {
        font-size: 1.6em;
    }
    
    #layout-info {
        width: 285px;
        font-size: 1.1em;
        margin-top: 1px;
    }

    .btn-about-us {
        background: #437DBF;
        color: #fff;
        font-size: 1.1em;
        border: none;
        border-radius: 5px;
        width: 150px;
        height: 50px;
    }
}

@media (min-width: 380px) {
    #container-showcase {
        padding-top: 25vh;
        margin-left: 10px;
    }
    
    .masthead h1 {
        font-size: 1.6em;
    }
    
    #layout-info {
        width: 350px;
        font-size: 1.1em;
        margin-top: 1px;
    }

    .btn-about-us {
        background: #437DBF;
        color: #fff;
        font-size: 1.1em;
        border: none;
        border-radius: 5px;
        width: 150px;
        height: 50px;
    }
}

@media (min-width: 1024px) {
    #container-showcase {
        padding-top: 40vh;
        margin-left: 25px;
    }

    .masthead h1 {
        font-size: 2.4em;
    }

    #layout-info {
        width: 550px;
        font-size: 1.5em;
        margin-top: 1vh;
    }
}

