.doubts-title {
    text-align: left;
    margin-top: 50px;
}

.space-between-doubts-cards {
    margin-bottom: 10px;
}

.doubts-card-question {
    margin: 0 auto;
}

.doubts-card-answer {
    margin-bottom: -10px;
}

.plus-icon {
    height: 50px;
    width: 50px;
    margin: 0 5px 0 -25px;
}

.plus-icon {
    color: #437DBF;
}

.accordion-button {
    background-color: #141414 !important;
    color: #fff !important;
    margin-bottom: -5px;
    height: 55px;
    border: none;
    box-shadow: none;
}

.accordion-button:after {
    background-image: none !important;
}

.accordion-button:not(.collapsed) {
    box-shadow: none !important;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-body {
    background-color: #141414 !important;
    color: #fff !important;
    border: none;
}

.accordion-item {
    background-color: #141414 !important;
    border: none !important;
    width: 100%;
    width: 100%; 
}

.bottom-bar-doubts-cards {
    border-radius: 10px;
    background-color: #fff;
    width: 100%;
    height: 5px;
    margin-top: 5px;
}

@media (min-width: 320px) {
    .doubts-text {
        width: 300px;
    }
    
    .doubts-card-question {
        width: 105%;
        padding-bottom: 5px;
    }
    
    .doubts-card-answer {
        width: 105%;
    }
}

@media (min-width: 380px) {
    .doubts-text {
        width: 300px;
    }
    
    .doubts-card-question {
        width: 100%;
        padding-bottom: 0px;
    }
    
    .doubts-card-answer {
        width: 105%;
    }
}

@media (min-width: 768px) {
    .doubts-text {
        width: 400px;
    }
    
    .doubts-card-question {
        width: 100%;
    }

    .doubts-card-answer {
        width: 100%;
    }
}