#services {
    padding-top: 50px;
}

.custom-card-service {
    color: #437DBF;
    text-align: center;
}

.services-info {
    text-align: center;
    color: #fff;
}

.container-icon {
    background-color: #fff;
    border-radius: 100%;
    margin: 0 auto;
}

.icon {
    color: #000;
}

.container-services-info-size {
    margin: 0 auto;
    margin-bottom: 10px;
}

@media (min-width: 320px) {
    .services-info {
        margin-top: -5px;
    }
    
    .container-icon {
        width: 100px;
        height: 100px;
        margin-bottom: 10px;
    }

    .space-top {
        margin-top: 25px;
    }
    
    .icon {
        height: 100px;
        width: 70px;
    }
    
    .container-services-info-size {
        width: 300px;
    }
    
    .bottom-bar-service {
        width: 300px;
        height: 5px;
        margin: 0 auto;
        border: none;
        border-radius: 10px;
        background-color: #437DBF;
    }
}

@media (min-width: 380px) {
    .services-info {
        margin-top: -5px;
    }
    
    .container-icon {
        width: 100px;
        height: 100px;
        margin-bottom: 10px;
    }

    .space-top {
        margin-top: 25px;
    }
    
    .icon {
        height: 100px;
        width: 70px;
    }
    
    .container-services-info-size {
        width: 300px;
    }
    
    .bottom-bar-service {
        width: 325px;
        height: 5px;
        margin: 0 auto;
        border: none;
        border-radius: 10px;
        background-color: #437DBF;
    }
}

@media (min-width: 768px) {
    .services-info {
        margin-top: 5px;
        margin-bottom: 50px;
    }

    .container-icon {
        width: 110px;
        height: 110px;
        margin-bottom: 10px;
    }
    
    #division-between-cards {
        border-left: 5px solid #437DBF;
        border-right: 5px solid #437DBF;
        height: 200px;
        margin-right: -15px;
        margin-left: -15px;
    }

    .space-top {
        margin-top: 0;
    }

    .icon {
        height: 110px;
        width: 80px;
    }
    
    .container-services-info-size {
        width: 95%;
    }

    .bottom-bar-service {
        display: none;
    }
}

@media (min-width: 1024px) {
    .services-info {
        margin-top: 5px;
        margin-bottom: 50px;
    }

    .container-icon {
        width: 120px;
        height: 120px;
        margin-bottom: 10px;
    }
    
    .space-top {
        margin-top: 0;
    }

    .icon {
        height: 120px;
        width: 90px;
    }
    
    .container-services-info-size {
        width: 90%;
    }

    .bottom-bar-service {
        display: none;
    }
}

@media (min-width: 1440px) {
    .services-info {
        margin-top: 5px;
        margin-bottom: 50px;
    }

    .container-icon {
        width: 120px;
        height: 120px;
        margin-bottom: 10px;
    }
    
    .space-top {
        margin-top: 0;
    }

    .icon {
        height: 120px;
        width: 90px;
    }
    
    .container-services-info-size {
        width: 350px;
    }

    .bottom-bar-service {
        display: none;
    }
}