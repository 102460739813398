.container{
    padding: 24px;
}

.container div{
    margin-bottom: 8px;
}

.container h3{
    margin-top: 32px;
}