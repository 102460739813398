.imgsContainer{
    display: flex;
    justify-content: center;
 }
 
 .mobileImg{
     height: 300px;
 }
 
 .imgRight{
     transform: translate(-20px);
     z-index: 0;
 }
 
 .imgLeft{
     transform: translate(20px);
     z-index: 0;
 }
 
 .imgCenter{
     z-index: 1;
     box-shadow: 12px 0 15px -4px rgba(0,0,0,0.75), -12px 0 8px -4px rgba(0,0,0,0.75);
     -webkit-box-shadow: 12px 0 15px -4px rgba(0,0,0,0.75), -12px 0 8px -4px rgba(0,0,0,0.75);
     -moz-box-shadow: 12px 0 15px -4px rgba(0,0,0,0.75), -12px 0 8px -4px rgba(0,0,0,0.75);
 }

@media (min-width: 320px) {
    .mobileImg {
        height: 250px;
    }
}

@media (min-width: 375px) {
    .mobileImg {
        height: 275px;
    }
}

@media (min-width: 425px) {
    .mobileImg {
        height: 300px;
    }
}

@media (min-width: 768px) {
    .mobileImg {
        height: 350px;
    }
}

@media (min-width: 1024px) {
    .mobileImg {
        height: 400px;
    }
}

@media (min-width: 1440px) {
    .mobileImg {
        height: 500px;
    }
}