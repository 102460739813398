.space-button {
    margin-top: 25px;
    margin-bottom: 80px;
    text-align: center;
}

.customBtnProject {
    background: #141414;
    color: #fff;
    border: 1px solid white;
    font-size: 1.1em;
    border-radius: 5px;
    width: 200px;
    height: 50px;
}

.customBtnProject:hover {
    background: #437DBF;
    color: rgb(0, 0, 0);
    border: none;
}