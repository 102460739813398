.imgCustom {
    width: 100%;
    height: 300px;
    margin-top: 50px;
    margin-bottom: 40px;
    border-radius: 5px;
}

@media (min-width: 1024px) {
    .imgCustom {
        height: 350px;
    }
}

@media (min-width: 1440px) {
    .imgCustom {
        height: 450px;
    }
}