* {
    margin: 0px;
    padding: 0px;
}

body {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    background: #141414;
}

h2 {
    color: #437DBF;
}

h3 {
    color: #437DBF;
}

p {
    color: #fff;
}

@media (min-width: 320px) {
    h2 {
        font-size: 1.7em;
    }
    
    h3 {
        font-size: 1.5em;
    }
    
    p {
        font-size: 1em;
    }
}

@media (min-width: 1020px) {
    h2 {
        font-size: 2.1em;
    }
    
    h3 {
        font-size: 1.8em;
    }
    
    p {
        font-size: 1.2em;
    }
}
