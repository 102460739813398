#container {
    margin-top: 250px;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.custom404 {
    font-size: 6em;
    color: #fff;
}