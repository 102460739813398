.Custom-working-title {
    color: white;
    margin-top: 50px;
}

.Custom-working-cross-bar {
    border: none;
    border-radius: 10px;
    background-color: #437DBF;
    margin: 0 auto;
}

.space-between-components {
    margin-top: 30px;
}

.container-Card {
    background: #1D1D1D;
    border-radius: 10px;
    margin: 0 auto;
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 25px;
    height: 200px;   
}

.custom-number {
    color: #fff;
    font-size: 1.3em;
}

.custom-subtitle {
    color: #437DBF;
    padding-top: 5px;
}


.swiper-pagination-bullet {
    background: #fff;
}

.swiper-pagination-bullet-active {
    background: #437DBF;
}

@media (min-width: 320px) { 
    .Custom-working-title {
        font-size: 1.6em;
    }
    
    .Custom-working-cross-bar {
        width: 285px;
        height: 5px;
    }
    
    .container-Card {
        width: 285px;
        padding-bottom: 15px;
    }

    .custom-text {
        width: 260px;
        font-size: 0.95em;
    }
}

@media (min-width: 380px) {    
    .Custom-working-title {
        font-size: 1.7em;
    }
    
    .Custom-working-cross-bar {
        width: 300px;
        height: 5px;
    }
    
    .container-Card {
        width: 350px;
    }

    .custom-text {
        width: 325px;
    }
}

@media (min-width: 768px) {
    .container-Card {
        width: 100%;
    }

    .custom-text {
        width: 95%;
    }
}

@media (min-width: 1024px) {
    .Custom-working-title {
        font-size: 2.1em;
    }
    
    .Custom-working-cross-bar {
        width: 400px;
        height: 8px; 
    }
    
    .container-Card {
        width: 100%;
        padding-top: 25px;
        padding-left: 25px;
    }

    .custom-text {
        width: 95%;
    }
}

@media (min-width: 1440px) {
    .container-Card {
        width: 100%;
    }

    .custom-text {
        width: 95%;
    }
}