.about-us-title {
    text-align: left;
}

@media (min-width: 320px) {
    .about-us-text {
        width: 105%;
    }
}

@media (min-width: 380px) {
    .about-us-text {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .about-us-text {
        width: 80%;
    }
}

@media (min-width: 1440px) {
    .about-us-text {
        width: 75%;
    }
}