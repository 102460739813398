.bg-budget {
    margin: 50px 0;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg-budget::before {
    content: "";
    background-image: url(../../assets/img-1.jpg);
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.15;
}

.container-budget {
    text-align: center;
}

.container-budget h2 {
    position: relative;
    color: #437DBF;
}

.text-budget {
    position: relative;
}

.button-budget {
    background: rgba(255, 255, 255, 0.2) !important;
    border: 2px solid #fff !important;
    position: relative;
}

.button-budget:hover {
    background: #437DBF !important;
    color: #000 !important;
    border: none !important;
}

@media (min-width: 320px) {
    .container-budget h2 {
        font-size: 1.5em;
        margin-top: 25px;
    }
    
    .text-budget {
        width: 285px;
    }

    .button-budget {
        width: 200px;
        height: 40px;
        font-size: 1em;
        margin-bottom: 25px;
    }
}


@media (min-width: 380px) {
    .container-budget h2 {
        font-size: 1.7em
    }

    .text-budget {
        width: 350px;
    }

    .button-budget {
        width: 225px;
        height: 45px;
        font-size: 1.1em;
    }
}

@media (min-width: 768px) {
    .container-budget h2 {
        font-size: 1.7em
    }

    .text-budget {
        width: 400px;
    }

    .button-budget {
        width: 250px;
        height: 45px;
        font-size: 1.1em;
    }
}

@media (min-width: 1024px) {
    .container-budget h2 {
        font-size: 2.1em
    }

    .text-budget {
        width: 400px;
    }

    .button-budget {
        width: 250px;
        height: 45px;
        font-size: 1.2em;
    }

    .bg-budget {
        height: 30vh;
    }
}