.custom-Card-title h3 {
    color: #437DBF;
}

.custom-Card-project-text {
    color: #fff;
}

.spaceProjectBtn {
    text-align: center;
}

.button-project {
    background: rgba(255, 255, 255, 0.1) !important;
    border: 2px solid #fff !important;
    border-radius: 10px;
}

.button-project:hover {
    background: #437DBF !important;
    color: #000 !important;
    border: 2px solid #437DBF !important;
}

@media (min-width: 320px) {
    .containerCardProject {
        margin: 0 auto;
        background: #1D1D1D !important;
        width: 285px;
    }
    
    .custom-img-project {
        height: 225px;
        border-radius: 10px 10px 0 0 ;
    }

    .custom-Card-title h3 {
        font-size: 1.3em;
    }
    
    .custom-Card-project-text {
        width: 250px;
    }

    .button-project {
        width: 220px !important;
        padding: 8px 0 !important;
    }
}

@media (min-width: 380px) {
    .containerCardProject {
        margin: 0 auto;
        background: #1D1D1D !important;
        width: 350px;
    }
    
    .custom-img-project {
        height: 225px;
        border-radius: 10px 10px 0 0 ;
    }
    
    .custom-Card-project-text {
        width: 315px;
    }

    .button-project {
        width: 220px !important;
        padding: 8px 0 !important;
    }
}


