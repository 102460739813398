nav {
    background: #141414;
}

#logo {
    width: 120px;
    padding-left: 10px;
}

#button-hamburguer {
    font-size: 1.6rem;
    margin-right: 15px;
}

.navbar-style-link {
    color: #fff;
    font-size: 1.4em;
    margin-bottom: 2px;
}

.left-sapce {
    margin-right: 25px;
}

.navbar-style-link:hover {
    color: #437DBF;
}

#ativa {
    padding-top: 5px;
    color: #437DBF;
}

@media (min-width: 1024px) {
    .navbar-style-link {
        font-size: 1.5em;
        padding-left: 50px;
    }
    
    #ativa {
        padding-top: 0px;
    }
}